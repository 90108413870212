import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, setToken } from '@/util/auth'
// import moment from 'moment'
// const nowTime = moment().format('YYYY-MM-DD HH:ss:mm')
// const nowTime = '2022-10-16 17:30:00'
// const startTime = '2022-10-15 15:30:00'
// const endTime = '2022-10-24 08:00:00'
NProgress.configure({ showSpinner: false })

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  // console.log(to, from, next, 55555555555)
  NProgress.start()
  // if (Object.is(to.name, 'maintain')) {
  // next();
  // return
  // }

  // if (moment(nowTime).diff(moment(startTime), 'seconds') > 0 && moment(nowTime).diff(moment(endTime), 'seconds') <= 0) {
  // next('/maintain')
  // } else {
  console.log('getToken() :>> ', getToken());
  if (to.path === '/singlelogin') {
    setToken(to.query.Authorization)
    store.dispatch("SingleLogin").then(() => {
      // console.log(this.redirect)
      next('/')
    }).catch(() => {
      next({ path: '/login' })
    });
    // next({ path: '/login' })
  } else if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next()
      NProgress.done()
    } else {

      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else if (to.path === '/logins') {
      if (to.query.error_msg) {
        Message.error(to.query.error_msg)
        next({ path: '/login' })
      } else if (to.query.access_token) {
        // setToken(to.query.access_token)
        // console.log(to.query.access_token, 11111)
        store.dispatch("Logins", to.query.access_token).then(() => {
          // console.log(this.redirect)
          next('/')
        }).catch(() => {
        });

      } else {
        next({ path: '/login' })
      }
      // next({ path: '/login' })
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
  // }

})

router.afterEach(() => {
  NProgress.done()
})
